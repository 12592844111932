import classes from "./Landing.module.scss";
import {useRef, useEffect, useState, useContext, useCallback} from "react";
import ValidUserContext from "../authCheck";
import signoutIcon from "../assets/fa-logout.svg";
import menuIcon from "../assets/fa-menu.svg";
import downloadIcon from "../assets/fa-download.svg";
import refreshIcon from "../assets/fa-refresh.svg";


import pdfIcon from "../assets/akar-icons_pdf.svg";
import csvIcon from "../assets/akar-icons_csv.svg";
import pwrpIcon from "../assets/akar-icons_pwrp.svg";
import excelIcon from "../assets/akar-icons_excel.svg";

import pngIcon from "../assets/akar-icons_png.svg";
import dividerIcon from "../assets/akar-icons_divider.svg";
import Dashboard from "./Dashboard"; 
import transparent_logo from "../assets/riot.jpg";
import pv_transparent_logo from "../assets/priorityview.png";
import fwd_curves from "../assets/fwdcurves.png";
import CryptoWidget from "./CryptoWidget";
import CoindeskWidget from "./CoindeskWidget";

import WeatherWidget from "./WeatherWidget";

import Navigation from "./Navigation";
import { slide as Menu } from 'react-burger-menu'
import { fetchSubscriptions, createSubscription, deleteSubscription } from './ApiService';


const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

const Landing = ({idleCountParam}) => {

    const currentNav = Object.entries(JSON.parse(localStorage.getItem("navigation")));
    const downloadMonthlyUrl = JSON.parse(localStorage.getItem("ms_download_url"));


    const filteredNav = currentNav.filter(([a, b]) => {
      return b.name.includes("Curves Export") == false
    });

    const exportNav = currentNav.filter(([a, b]) => {
      return b.name.includes("Curves Export") == true
    });

    const sortedNav = filteredNav.sort((a, b) => {
      if(a[1].name > b[1].name) {
        // If two elements have same number, then the one who has larger rating.average wins
        return 1;
      } else {
        // If two elements have different number, then the one who has larger number wins
        return -1;
      }
    });

    var buttons = Object.keys(sortedNav).map(key => sortedNav[key][1].name)
    var links = Object.keys(sortedNav).map(key => sortedNav[key][1].dashboards[0])
    var dashboardids = Object.keys(sortedNav).map(key => sortedNav[key][1].dashboard_ids[0])

    const [activeTab, setActiveTab] = useState(0);
    const [menuOpen, setMenuOpen] = useState(isMobileDevice()? false: true);

    const [activeButton, setActiveButton] = useState(0);
    const [activeDashboard, setActiveDashboard] = useState(true);
    const [activeURL, setActiveURL] = useState(links[0]);
    const [activeDashboardId, setActiveDashboardId] = useState(dashboardids[0]);
    const [displayTabs, setDisplayTabs] = useState(false);
    const [displayToolbarButtons, setDisplayToolbarButtons] = useState(true);

    const [defultFolder, setDefaultFolder] = useState('Home');
    const [defultFolderId, setDefaultFolderId] = useState('Home');
    const [defaultEmail, setDefaultEmail] = useState(localStorage.getItem("login-name") ?? "");
    const [defaultUser, setDefaultUser] = useState(JSON.parse(localStorage.getItem("user-name")) ?? "");
    const [defaultGroup, setDefaultGroup] = useState(JSON.parse(localStorage.getItem("group")) ?? "default");
    const [defaultCompany, setDefaultCompany] = useState((JSON.parse(localStorage.getItem("company_logo")) || JSON.parse(localStorage.getItem("company")))?? "default");
    const [defaultClient, setDefaultClient] = useState((JSON.parse(localStorage.getItem("client_logo")) || JSON.parse(localStorage.getItem("client"))) ?? "default");

    const [breadCrumbs, setBreadCrumbs] = useState([{"id":buttons[0], "name":buttons[0]}]);
    const [currentButtons, setCurrentButtons] = useState(buttons);
    const [currentLinks, setCurrentLinks] = useState(links);
    const [currentIds, setCurrentIds] = useState(dashboardids);

    const [refreshSpin, setRefreshSpin] = useState(false);
    const [subscriptions, setSubscriptions] = useState({});
    const [subscriptionCheck, setSubscriptionCheck] = useState(false);
    const [updatingSub, setupdatingSub] = useState(false);

    const [idleCount, setIdleCount] = useState(idleCountParam);


    const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);
    
    const containerRef = useRef(null);

    //const [dashboardRef, setDashboardRef] = useState(undefined)
    const dashboardRef = useRef(null)

    const validUserContext = useContext(ValidUserContext);

    useEffect(() => {
        const checkScrollbars = () => {
            if (containerRef.current) {
                const container = containerRef.current;
                setHasVerticalScrollbar(container.scrollHeight > container.clientHeight);
            }
        };
        // Initial check
        checkScrollbars();
        // Check on window resize
        window.addEventListener('resize', checkScrollbars);
        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', checkScrollbars);
    }, []);

    useEffect(() => {
      if (idleCountParam != idleCount) {
        setIdleCount(idleCountParam);
        handleBackgroundRefresh()
      }
  }, [idleCountParam]);

    // useEffect(() => {
    //   const loadData = async () => {
    //     try {
    //       const data = 
    //       {
    //         "useremail": defaultEmail
    //       }
    //       const result = await fetchSubscriptions(data);
    //       if (Object.keys(result).includes(activeDashboardId)) {
    //         setSubscriptionCheck(true);
    //       }

    //       setSubscriptions(result);
    //     } catch (error) {
          
    //     } finally {
          
    //     }
    //   };
  
    //   loadData();
    // }, [activeURL]);
  

    const handleDashboardClick = (link) => {
        setActiveDashboard(true)
        setActiveURL(link)
        setDisplayTabs(false)
        setDisplayToolbarButtons(true)
    }

    const handleFolderClick = (name, id) => {
      setDefaultFolder(name)
      setDefaultFolderId(id)
      breadCrumbs.push({"id":id, "name":name})
      setBreadCrumbs(breadCrumbs)
    }

    const handleLogoutClick = () => {
        validUserContext.logoutUser()
    }
  
    const handleExportPDFClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].displayDialogAsync("export-pdf");
    }

    const handleExportCSVClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].displayDialogAsync("export-csv");
    }

    const handleExportPNGClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].exportImageAsync();
    }

    const handleExportPWRPClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].displayDialogAsync("export-powerpoint");
    }

    const handleCrossTabClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].displayDialogAsync("export-cross-tab");
    }

    const handleTriggerRefresh = () => {
      setRefreshSpin(true)
      dashboardRef.current.firstChild.firstChild.childNodes[1].refreshDataAsync().then(function() {
        console.log("Dashboard refreshed.");
        setRefreshSpin(false)
        }).catch(function(error) {
            console.error("Error refreshing dashboard:", error);
            setRefreshSpin(false)
        });
    }

    const handleBackgroundRefresh = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].refreshDataAsync().then(function() {
        console.log("Dashboard refreshed in background.");
        }).catch(function(error) {
            console.error("Error refreshing dashboard:", error);
        });
    }

    const handleDownloadClick = () => {

      // Create a temporary anchor element
      const anchor = document.createElement('a');
      anchor.href = downloadMonthlyUrl;
      var randomNumber = Math.floor(Math.random() * 1000000);

      anchor.download = `MonthlyScorecard.pptx?v=${randomNumber}`; // Specify the file name for the download
  
      // Append the anchor to the body
      document.body.appendChild(anchor);
  
      // Trigger the download by programmatically clicking the anchor
      anchor.click();
  
      // Remove the anchor from the body after download
      document.body.removeChild(anchor);
    }


    const handleLogoClick = () => {
      setActiveDashboard(false)
    }
    const handleCheckboxChange = () => {
      const createSub = async () => {
        try {
          const data = 
          {
            "useremail": defaultEmail,
            "dashboardid": activeDashboardId,
            "link": activeURL
          }
          setSubscriptionCheck(true);
          const result = await createSubscription(data);
          const newSubscriptions = { ...subscriptions };
          newSubscriptions[activeDashboardId] = defaultEmail;
      
          // Update the state with the new object
          setSubscriptions(newSubscriptions);
          setupdatingSub(false)
          console.log("Subscription created:", result);


        } catch (error) {
          console.error('Caught an error:', error);
        } finally {
          
        }
      };

      const deleteSub = async () => {
        try {
          const data = 
          {
            "useremail": defaultEmail,
            "dashboardid": activeDashboardId
          }
          setSubscriptionCheck(false);
          const result = await deleteSubscription(data);
          const newSubscriptions = { ...subscriptions };
          delete newSubscriptions[activeDashboardId];
      
          // Update the state with the new object
          setSubscriptions(newSubscriptions);
          setupdatingSub(false)

          console.log("Subscription deleted:", result);


        } catch (error) {
          console.error('Caught an error:', error);
        } finally {
          
        }
      };
      if (!updatingSub){
        setupdatingSub(true)

        if (Object.keys(subscriptions).includes(activeDashboardId)) {
          deleteSub();
        } else {
          createSub();
        }
      }
    }

    const handleButtonClick = (tabIndex, tabText) => {
      validUserContext.localAuthCheck(false);
      setActiveButton(tabIndex);
      setActiveURL(currentLinks[tabIndex])
      setActiveDashboard(true)
      setActiveDashboardId(currentIds[tabIndex])
      setDefaultFolder('Home')
      setDisplayTabs(false)
      if (isMobileDevice() ){
        setMenuOpen(false)
      }
      setDisplayToolbarButtons(true)
      let newBreadCrumbs
      // Remove elements after the specific element
      newBreadCrumbs = [{"id":tabText, "name":tabText}]
      setBreadCrumbs(newBreadCrumbs)
      setSubscriptionCheck(false);
    };

    const handleBreadCrumbClick = (folderName) => {
      setActiveDashboard(false)
      setDefaultFolder(folderName.name)
      setDefaultFolderId(folderName.id)

      let indexToRemove = breadCrumbs.indexOf(folderName);
      let newBreadCrumbs
      // Remove elements after the specific element
      if (indexToRemove !== -1) {
        newBreadCrumbs = breadCrumbs.slice(0, indexToRemove + 1);
      }
      setBreadCrumbs(newBreadCrumbs)
    }

    const handleMenuClick  = () => {
      if (menuOpen) {
        setMenuOpen(false)
      } else {
        setMenuOpen(true)
      }
    };

    const renderContent = () => {
      if (activeDashboard)
          return renderDashboard()
      else 
          return renderNavigation()
    } 

    const renderNavigation = () => {
      return (
        <div>
            <Navigation defaultFolder={defultFolder} defaultFolderId={defultFolderId} onDashboardClick={handleDashboardClick} onFolderClick={handleFolderClick}></Navigation>
        </div>
      )
    }


    const renderDashboard = () => {
      return (
        <div>
            <Dashboard dashboardLinkProp={activeURL} displayTabs={displayTabs} idleCount={idleCount}></Dashboard>
        </div>
      )
    };
    
    const rendeToolbar = () => {  
      
    }
    
    
    const renderButtons = () => {  
      return currentButtons.map((buttonText, index) => {
        if (activeButton === index) {
            return (<div className={`${classes.sideButton} ${classes.active}`} onClick={() => handleButtonClick(index, buttonText)}>{buttonText.replace(/^\d+\.\s*/, '')}</div>)
        } else {
            return (<div className={`${classes.sideButton}`} onClick={() => handleButtonClick(index, buttonText)}>{buttonText.replace(/^\d+\.\s*/, '')}</div>)
        }
      });
    };
    var randomNumber = Math.floor(Math.random() * 1000000);


    var companyLink = `https://storage.googleapis.com/pv_portal_artifacts/${defaultGroup.toLowerCase()}.png?v=${randomNumber}`
    var defaultLink = `https://storage.googleapis.com/pv_portal_artifacts/priority_power.png?v=${randomNumber}`
    const handleError = (event) => {
      event.target.src = defaultLink;
    };

    const renderFull = () => {
      return (
          <div className={`${classes.landing}`}>

            <div className={`${menuOpen ? classes.sidebar : classes.sidebarClosed} `}
                  ref={containerRef}>
            <div className={`${classes.sidebarlogo}`}/>
            <div className={`${classes.sidebarlogoCircle}`}>
              <img className={classes.sidebarlogo} src={companyLink} onError={handleError}></img>
            </div>
            <div className={`${classes.sideState}`}>{defaultUser}</div>
            <div className={`${classes.topWidget}`}>
              <WeatherWidget></WeatherWidget>
            </div>
            <CryptoWidget hasVerticalScrollbar={hasVerticalScrollbar}></CryptoWidget>
            <div class={`${hasVerticalScrollbar ? "social_container withscrollbar" : "social_container"}`} >
              <a target="_blank" href="https://www.x.com" class="fa fa-brands fa-x-twitter"></a>
              <a target="_blank" href="https://www.google.com" class="fa fa-brands fa-google"></a>
              <a target="_blank" href="https://www.linkedin.com" class="fa fa-brands fa-linkedin"></a>
              <a target="_blank" href="https://www.ercot.com" class="fa fa-brands fa-ercot"></a>

            </div>
            {renderButtons()}
            <a target="_blank" href="https://prioritypower.com">
              <div className={`${classes.sidePortalName}`}>
                <img className={classes.sidebarlogo} src={pv_transparent_logo}></img>
              </div>
            </a>
          </div>
          <div className={`${menuOpen ? classes.contentblock : classes.contentblockMobile}`}>
            <div className={`${classes.toolbar}`}>
                <img
                      className={classes.icon}
                      src={signoutIcon}
                      alt="Signout icon"
                      htmlFor="sign-out"
                      onClick={() => handleLogoutClick()}
                ></img>
                <span className={`${classes.logout}`} onClick={() => handleLogoutClick()}>Logout</span>
                {displayToolbarButtons? 
                (
                  <div>
                      <img
                      className={classes.dividericon}
                      src={dividerIcon}
                      alt="Divider icon"
                      htmlFor="divider"
                      ></img>
                      <img
                      className={classes.pdficon}
                      src={pdfIcon}
                      alt="PDF icon"
                      htmlFor="download-pdf"
                      onClick={() => handleExportPDFClick()}
                      ></img>
                      <img
                      className={classes.pdficon}
                      src={pngIcon}
                      alt="PNG icon"
                      htmlFor="download-png"
                      onClick={() => handleExportPNGClick()}
                      ></img>
                      <img
                      className={classes.pwrpicon}
                      src={pwrpIcon}
                      alt="Power Point icon"
                      htmlFor="download-pwrp"
                      onClick={() => handleExportPWRPClick()}
                      ></img>
                      <img
                      className={classes.excelicon}
                      src={excelIcon}
                      alt="Excel icon"
                      htmlFor="download-excel"
                      onClick={() => handleCrossTabClick()}
                      ></img>
                      {downloadMonthlyUrl && (
                        <>
                          <img
                          className={classes.dividericon}
                          src={dividerIcon}
                          alt="Divider icon"
                          htmlFor="divider"
                          ></img>
                          <img
                          className={classes.downloadIcon}
                          src={downloadIcon}
                          alt="Score icon"
                          htmlFor="download-score"
                          onClick={() => handleDownloadClick()}
                          ></img>
                          <span className={`${classes.logout}`} onClick={() => handleDownloadClick()}>Monthly Scorecard</span>
                        </>
                      )}
                      <img
                      className={classes.dividericon}
                      src={dividerIcon}
                      alt="Divider icon"
                      htmlFor="divider"
                      ></img>
                      {/* {activeURL.includes("MarketOverview") && (
                        <>
                            <div className={`${classes.subContainer}` } onClick={() => handleCheckboxChange()}>
                              <label className={`${classes.subText}` } for="exampleCheckbox">Subscribe</label>
                              <input className={`${classes.subCheckbox}` } type="checkbox" disabled={updatingSub} checked={subscriptionCheck} id="subCheckbox" name="subCheckbox"></input>
                            </div>
                            <img
                            className={classes.dividericon}
                            src={dividerIcon}
                            alt="Divider icon"
                            htmlFor="divider"
                            ></img>
                        </>

                      )} */}
                      <img
                      className={`${classes.refreshicon} ${refreshSpin ? classes.spin : ''}`}
                      src={refreshIcon}
                      alt="Excel icon"
                      htmlFor="download-excel"
                      onClick={() => handleTriggerRefresh()}
                      ></img>

                    </div>
                ):(
                  <div></div>
                )
                }
                
                {/* {breadCrumbs.map((folderName) => (
                  <div className={`${classes.breadcrumb}`}>{folderName.name} &gt;</div>
                ))} */}
            </div>
            <div className={classes.dashboardblock} ref={dashboardRef} >
                {renderContent()}
            </div>
          </div>
          
        </div>
      )
    }

    const renderMobile = () => {
      return (
            <div className={`${classes.landing}`}>
                <div className={`${menuOpen ? classes.sidebar : classes.sidebarClosed}`}>  
                  <div className={`${classes.sidebarlogo}`}/>
                  <div className={`${classes.sidebarlogoCircle}`}>
                    <img className={classes.sidebarlogo} src={companyLink} onError={handleError}></img>
                  </div>                  
                  <div className={`${classes.sideState}`}>{defaultUser}</div>
                  {renderButtons()}
                  <div className={`${classes.sidePortalName}`}>
                    <img className={classes.sidebarlogo} src={pv_transparent_logo}></img>
                  </div>
                </div>
                <div className={`${classes.contentblockMobile}`}>
                  <div className={`${classes.toolbar}`}>
                      <img
                            className={classes.icon}
                            src={signoutIcon}
                            alt="Signout icon"
                            htmlFor="sign-out"
                            onClick={() => handleLogoutClick()}
                      ></img>
                      <span className={`${classes.logout}`} onClick={() => handleLogoutClick()}>Logout</span>
                      <img className={classes.toolbarMobileLogo} src={pv_transparent_logo}></img>
                      <img
                        className={classes.menuicon}
                        src={menuIcon}
                        alt="Menu icon"
                        htmlFor="menu-click"
                        onClick={() => handleMenuClick()}
                      ></img>
                  </div>
                  <div className={classes.dashboardblock} ref={dashboardRef} >
                      {renderContent()}
                  </div>
                </div>
            </div>
      )
    }


    // var jwtToken = JSON.parse(localStorage.getItem("tableau-login-data"));
    // localStorage.setItem("tableau-login-data", JSON.stringify("redeemed"));

    // var inputProps = {
    // };
    
    // if (jwtToken != "redeemed") {
    //   inputProps.token = jwtToken;
    // }

    return (
      <div>
        {isMobileDevice() ?
          renderMobile()
          :
          renderFull()
        }
      </div>
    );
  };
  
  export default Landing;
